html {
  height: 100%;
  background: #efefef;
  touch-action: manipulation;
}

.dr-text-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  max-height: 1.5em;
}
